import { useEffect, useState } from "react";
import { formatDate, weeklyDate } from "../../components/EarningsReportData";
import { getEarningsData, getNewEarningsData } from "../api";
import { User } from "../types";

export type EarningsData = {
  ticker: string;
  symbol: string;
  date: string;
  logo_url: string;
  sector: string;
  country_iso: string;
  name: string;
  currency: string;
  eps_actual: number;
  eps_estimate: number;
  hour: string;
  quarter: number;
  revenue_actual: number;
  revenue_estimate: number;
  year: number;
  total_shares_value: number;
};

export function useEarningsData(user: User) {
  const [earnings, setEarnings] = useState<EarningsData[]>();
  // const [Monday_BMO, setMonday_BMO] = useState<EarningsData[]>();
  // const [Monday_AMC, setMonday_AMC] = useState<EarningsData[]>();
  // const [Tuesday_BMO, setTuesday_BMO] = useState<EarningsData[]>();
  // const [Tuesday_AMC, setTuesday_AMC] = useState<EarningsData[]>();
  // const [Wednesday_BMO, setWednesday_BMO] = useState<EarningsData[]>();
  // const [Wednesday_AMC, setWednesday_AMC] = useState<EarningsData[]>();
  // const [Thursday_BMO, setThursday_BMO] = useState<EarningsData[]>();
  // const [Thursday_AMC, setThursday_AMC] = useState<EarningsData[]>();
  // const [Friday_BMO, setFriday_BMO] = useState<EarningsData[]>();
  // const [Friday_AMC, setFriday_AMC] = useState<EarningsData[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [Monday_BMO, setMonday_BMO] = useState<EarningsData[]>([]);
  const [Tuesday_BMO, setTuesday_BMO] = useState<EarningsData[]>([]);
  const [Wednesday_BMO, setWednesday_BMO] = useState<EarningsData[]>([]);
  const [Thursday_BMO, setThursday_BMO] = useState<EarningsData[]>([]);
  const [Friday_BMO, setFriday_BMO] = useState<EarningsData[]>([]);
  const [Monday_AMC, setMonday_AMC] = useState<EarningsData[]>([]);
  const [Tuesday_AMC, setTuesday_AMC] = useState<EarningsData[]>([]);
  const [Wednesday_AMC, setWednesday_AMC] = useState<EarningsData[]>([]);
  const [Thursday_AMC, setThursday_AMC] = useState<EarningsData[]>([]);
  const [Friday_AMC, setFriday_AMC] = useState<EarningsData[]>([]);
  const dm = weeklyDate(new Date(new Date().toUTCString().slice(0, -4)));
  const dt = weeklyDate(new Date(new Date().toUTCString().slice(0, -4)));
  const dw = weeklyDate(new Date(new Date().toUTCString().slice(0, -4)));
  const dth = weeklyDate(new Date(new Date().toUTCString().slice(0, -4)));
  const df = weeklyDate(new Date(new Date().toUTCString().slice(0, -4)));
  const Monday = formatDate(dm, 0);
  const Tuesday = formatDate(dt, 1);
  const Wednesday = formatDate(dw, 2);
  const Thursday = formatDate(dth, 3);
  const Friday = formatDate(df, 4);

  const tempMonday_BMO: EarningsData[] = [];
  const tempMonday_AMC: EarningsData[] = [];
  const tempTuesday_BMO: EarningsData[] = [];
  const tempTuesday_AMC: EarningsData[] = [];
  const tempWednesday_BMO: EarningsData[] = [];
  const tempWednesday_AMC: EarningsData[] = [];
  const tempThursday_BMO: EarningsData[] = [];
  const tempThursday_AMC: EarningsData[] = [];
  const tempFriday_BMO: EarningsData[] = [];
  const tempFriday_AMC: EarningsData[] = [];
  useEffect(() => {
    setLoading(true);
    getEarningsData(user!)
      .then(({ data }) => {
        setEarnings(data);
        for (const res of data) {
          if (res.date === Monday) {
            if (res.hour === "bmo") {
              tempMonday_BMO.push(res);
            } else if (res.hour === "amc") {
              tempMonday_AMC.push(res);
            }
          }
          console.log(res.date);

          if (res.date === Tuesday) {
            if (res.hour === "bmo") {
              tempTuesday_BMO.push(res);
            } else if (res.hour === "amc") {
              tempTuesday_AMC.push(res);
            }
          }
          if (res.date === Wednesday) {
            if (res.hour === "bmo") {
              tempWednesday_BMO.push(res);
            } else if (res.hour === "amc") {
              tempWednesday_AMC.push(res);
            }
          }
          if (res.date === Thursday) {
            if (res.hour === "bmo") {
              tempThursday_BMO.push(res);
            } else if (res.hour === "amc") {
              tempThursday_AMC.push(res);
            }
          }
          if (res.date === Friday) {
            if (res.hour === "bmo") {
              tempFriday_BMO.push(res);
            } else if (res.hour === "amc") {
              tempFriday_AMC.push(res);
            }
          }
        }
        tempMonday_BMO.sort((a, b) => b.revenue_estimate - a.revenue_estimate);
        tempMonday_AMC.sort((a, b) => b.revenue_estimate - a.revenue_estimate);
        tempTuesday_BMO.sort((a, b) => b.revenue_estimate - a.revenue_estimate);
        tempTuesday_AMC.sort((a, b) => b.revenue_estimate - a.revenue_estimate);
        tempWednesday_BMO.sort(
          (a, b) => b.revenue_estimate - a.revenue_estimate
        );
        tempWednesday_AMC.sort(
          (a, b) => b.revenue_estimate - a.revenue_estimate
        );
        tempThursday_BMO.sort(
          (a, b) => b.revenue_estimate - a.revenue_estimate
        );
        tempThursday_AMC.sort(
          (a, b) => b.revenue_estimate - a.revenue_estimate
        );
        tempFriday_BMO.sort((a, b) => b.revenue_estimate - a.revenue_estimate);
        tempFriday_AMC.sort((a, b) => b.revenue_estimate - a.revenue_estimate);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        console.log("done");
        setLoading(false);
      });
  }, []);
  return {
    earnings,
    loading,
    error,
    Monday_BMO,
    Monday_AMC,
    Tuesday_BMO,
    Tuesday_AMC,
    Wednesday_BMO,
    Wednesday_AMC,
    Thursday_BMO,
    Thursday_AMC,
    Friday_BMO,
    Friday_AMC,
  };
}
/* "importance": 2,
                "symbol": "SAVE",
                "date": "2024-05-06",
                "time": "05:50:00",
                "title": "Spirit Airlines",
                "volume": 6668167,
                "marketCap": 372303400,
                "exchange": "NYSE",
                "timeRelease": "bmo",
                "image": "https://financialmodelingprep.com/image-stock/SAVE.png" 
                */

export type NewEarningsData = {
  importance: number;
  symbol: string;
  date: string;
  time: string;
  title: string;
  volume: number;
  marketCap: number;
  exchange: string;
  timeRelease: string;
  image: string;
  emoji?: string;
};
export function useNewEarningsData(user: User) {
  const [earnings, setEarnings] = useState<{
    [key: string]: {
      bmo: NewEarningsData[];
      amc: NewEarningsData[];
    };
  }>();
  const [error, setError] = useState();
  // const [Monday_BMO, setMonday_BMO] = useState<EarningsData[]>();
  // const [Monday_AMC, setMonday_AMC] = useState<EarningsData[]>();
  // const [Tuesday_BMO, setTuesday_BMO] = useState<EarningsData[]>();
  // const [Tuesday_AMC, setTuesday_AMC] = useState<EarningsData[]>();
  // const [Wednesday_BMO, setWednesday_BMO] = useState<EarningsData[]>();
  // const [Wednesday_AMC, setWednesday_AMC] = useState<EarningsData[]>();
  // const [Thursday_BMO, setThursday_BMO] = useState<EarningsData[]>();
  // const [Thursday_AMC, setThursday_AMC] = useState<EarningsData[]>();
  // const [Friday_BMO, setFriday_BMO] = useState<EarningsData[]>();
  // const [Friday_AMC, setFriday_AMC] = useState<EarningsData[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getNewEarningsData(user!)
      .then(({ data }) => {
        console.log(data);
        setEarnings(data);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        console.log("done");
        setLoading(false);
      });
  }, []);
  console.log(earnings);
  return { earnings, loading, error };
}
